
body {
    margin: 10px;
}

#add-spice {
    margin: 8px;
}

.modal-button {
    margin-right:  8px;
}

#category {
    height: 30px;
}

.spice-buttons Button {
    margin: 5px;
}

.spice-date td {
    text-align: center;
}

.spice-date {
    font-style:normal;
}

.past-spice-date {
    background-color: red;
    font-style: italic;
}

#spice-table td {
    vertical-align: middle;
}

.loading {
    position: relative;
    top: 100px;
    left: 20px;
    background-color: transparent;
}
